import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";
import { Button, Typography, Box, Container } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from "react-router-dom";

export default function PaymentSuccess() {
  return (
    <>
      <Layout />
        <Container 
        maxWidth="sm" 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center'
        }}
      >
        <Box
          sx={{
            backgroundColor: '#4caf50',
            width: 100,
            height: 100,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 4px 10px rgba(0,0,0,0.2)', 
            mb: 3,
          }}
        >
          <CheckIcon sx={{ fontSize: 60, color: '#fff' }} />
        </Box>

        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
          Payment complete
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for your payment
        </Typography>

        <Button 
          variant="contained" 
          component={Link} 
          to="/"
          sx={{
            mt: 3, 
            backgroundColor: "#1D5271", 
            color: "white",
            textTransform: 'none', 
            padding: "10px 20px"
          }}
        >
          Return Home
        </Button>
      </Container>
      <Footer />
    </>
  );
}