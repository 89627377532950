import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";
import { Typography, Box, Container, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";

export default function PaymentFailed() {
  return (
    <>
      <Layout />
      <Container 
        maxWidth="sm" 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center'
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 100,
            height: 100,
            borderRadius: '50%',
            backgroundColor: '#F44336', 
            mb: 3,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
          }}
        >
          <CloseIcon sx={{ fontSize: 60, color: 'white' }} />
        </Box>

        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
          Payment failed
        </Typography>

        <Typography variant="body1" gutterBottom>
          It looks like we couldn't process your payment. Please double check your payment details and try again when you are ready.
        </Typography>

        <Typography variant="body1" fontWeight="bold" gutterBottom>
          If the issue persists, feel free to contact our support team for assistance on <a href="tel:0330 818 6000"> 0330 818 6000 </a>.
        </Typography>

        <Button 
          variant="contained" 
          component={Link} 
          to="/"
          sx={{
            mt: 3, 
            backgroundColor: "#1D5271", 
            color: "white",
            textTransform: 'none', 
            padding: "10px 20px"
          }}
        >
          Return Home
        </Button>
      </Container>
      <Footer />
    </>
  );
}
