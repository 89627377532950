import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";
import { Box, Typography } from "@mui/material";

export default function Contact() {

  return (
    <>
      <Layout />
      <Box sx={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%" }}>
        <Typography
          variant="h5"
          sx={{
            color: "#000",
            marginBottom: "1rem",
            fontWeight: 800,
          }}
        >
          Our team are ready to support you
        </Typography>
        <Typography paragraph>
          Looking for support and would like to speak to us directly?
          <br /><br />
          Call us Monday- Friday, 9am to 5:30pm - on <a href="tel:0330 818 6000"> 0330 818 6000 </a>
          <br /><br />
          You can also email the team (please include your account reference number so we know it's you), at: <a href="mailto:info@intrummortgages.co.uk">info@intrummortgages.co.uk.</a>
        </Typography>
        <Typography component="div">
          Need to send us documents, forms or supporting evidence? The fastest way for us to receive and process these things is via email, however if you'd like to write to us or if we've asked specifically for things to be sent to us <br /> in the post, this is our address:  Intrum Mortgages, Capella Building, 60 York Street, Glasgow, G2 8JX
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "#000",
            marginTop: "2rem",
            marginBottom: "1rem",
            fontWeight: 800,
          }}
        >
          Make a complaint by phone
        </Typography>
        <Typography paragraph>
          Call the team directly on <a href="tel:0330 818 6000"> 0330 818 6000 </a> (calls may be recorded for quality and training purposes)
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "#000",
            marginBottom: "1rem",
            marginTop: "2rem",
            fontWeight: 800,
          }}
        >
          Make a complaint by letter
        </Typography>
        <Typography paragraph>
          Submit your complaint in writing to:<br />
          Intrum Mortgages<br /> Capella Building<br /> 60 York Street<br /> Glasgow<br /> G2 8JX
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "#000",
            marginBottom: "1rem",
            marginTop: "2rem",
            fontWeight: 800,
          }}
        >
          Make a complaint by email
        </Typography>
        <Typography paragraph>
          <a href="mailto:info@intrummortgages.co.uk">info@intrummortgages.co.uk</a><br /><br />
          Please ensure your reference number is stated on all correspondence to avoid any delays in processing your complaint.
        </Typography>
      </Box>
      <Footer sx={{ backgroundColor: "#0074B8" }} />
    </>
  );
}
