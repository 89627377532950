import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import MakePayment from "./components/pages/MakePayment";
import About from "./components/pages/About";
import Help from "./components/pages/Help";
import RebrandArticle from "./components/pages/RebrandArticle";
import FinanceControlArticle from "./components/pages/FinanceControlArticle";
import PayingTooMuchArticle from "./components/pages/PayingTooMuchArticle";
import Faqs from "./components/pages/Faqs";
import ScrollToTop from "./components/ScrollToTop";
import PaymentSuccess from "./components/pages/PaymentSuccess";
import PaymentFailed from "./components/pages/PaymentFailed";
function App() {
    const router = createBrowserRouter([
      {
        path: "/",
        element: (
          <>
            <ScrollToTop />
            <Home />
          </>
        ),
      },
      {
        path: "/Contact",
        element: (
          <>
            <ScrollToTop />
            <Contact />
          </>
        ),
      },
      {
        path: "/About",
        element: (
          <>
            <ScrollToTop />
            <About />
          </>
        ),
      },
      {
        path: "/MakePayment",
        element: (
          <>
            <ScrollToTop />
            <MakePayment />
          </>
        ),
      },
      {
        path: "/RebrandArticle",
        element: (
          <>
            <ScrollToTop />
            <RebrandArticle />
          </>
        )
      },
      {
        path: "/FinanceControlArticle",
        element: (
          <>
            <ScrollToTop />
            <FinanceControlArticle />
          </>
        )
      },
      {
        path: "/PayingTooMuchArticle",
        element: (
          <>
            <ScrollToTop />
            <PayingTooMuchArticle />
          </>
        )
      },
      {
        path: "/Faqs",
        element: (
          <>
            <ScrollToTop />
            <Faqs />
          </>
        )
      },
      {
        path: "/Help",
        element: (
          <>
            <ScrollToTop />
            <Help />
          </>
        ),
      },
      {
        path: "/making-repayments/thank-you",
        element: (
          <>
            <ScrollToTop />
            <PaymentSuccess />
          </>
        ),
      },
      {
        path: "/making-repayments/sorry",
        element: (
          <>
            <ScrollToTop />
            <PaymentFailed />
          </>
        ),
      },
    ]);

    return (
      <RouterProvider router={router} />
    );
  }


export default App;
