import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";
import { Box, Typography } from "@mui/material";

export default function Contact() {
    return (
        <>
            <Layout />
            <Box sx={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%" }}>
                <Typography
                    variant="h5"
                    sx={{
                        color: "#000",
                        marginBottom: "1rem",
                        fontWeight: 800,
                    }}
                >
                    Rebrand FAQs
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        color: "#000",
                        marginBottom: "1rem",
                        fontWeight: 800,
                    }}
                >
                    Why have you changed your name to Intrum Mortgages?
                </Typography>
                <Typography paragraph>
                    In June 2023, Mars Capital became part of Intrum Group. The acquisition has given us a fantastic opportunity to enhance how we operate, and now that the move is finalised, we’re excited to be rebranding in line with the wider business.
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Who owns my Mortgage?
                </Typography>
                <Typography paragraph>
                    Intrum Mortgages is a very experienced mortgage lender and administrator, is authorised and regulated by the FCA and is a member of the Council of Mortgage Lenders.
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
                >
                    Has my Mortgage been sold to another company?
                </Typography>
                <Typography paragraph>
                    No, it hasn’t. It’s exactly the same loan supported by the same fantastic team of professionals, and there are no changes to it other than the name.
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
                >
                    Will I receive monthly and/or annual statements?
                </Typography>
                <Typography paragraph>
                    Yes. You’ll receive these if you have been already. They will have our new logo and may look slightly different, but all of the details you’re used to receiving will be included.
                </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    Do I need to take any action with regards to how I make payments? For example, if I pay by Direct Debit every month, will I need to do anything or contact my bank about this?
                </Typography>
                <Typography paragraph>
                    No. Our payment details remain the same, and only our name is changing. So, you do not need to take any action.
                </Typography>

                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    Is my Data affected? And who will now be responsible for my Data and how it’s used?
                </Typography>
                <Typography paragraph>
                    Once the name-change has happened, a new Privacy Notice will be uploaded to our rebranded website, and all of the details of how your data is managed will be listed there, confirming ‘Intrum Mortgages’ as the Data Controller. To be clear, there will be no changes to the way in which we store and process your data as a result of this change.
                    <br />
                    If you’d like to ask something else, or for questions about your account in general – we’re here to help. Feel free to drop us an email <a href="mailto:info@intrummortgages.co.uk">info@intrummortgages.co.uk.</a> or call the team on <a href="tel:0330 818 6000">0330 818 6000.</a></Typography>

                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    How will you keep me updated with my account information?
                </Typography>
                <Typography paragraph>
                    Intrum Mortgages will send detailed statements to you every month. You will also receive an annual statement
                </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    What should I do if I have any questions about this change?
                </Typography>
                <Typography paragraph>
                    If you’d like to ask something else, or for questions about your account in general – we’re here to help. Feel free to contact us.
                </Typography>
            </Box>
            <Box sx={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%" }}>
                <Typography variant="h5"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    General FAQs
                </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    Who are Intrum Mortgages?
                </Typography>
                <Typography paragraph>
                    Intrum Mortgages buy and manage hundreds of millions of pounds worth of mortgages. We are the legal owners of these accounts and pride ourselves on helping our customers work towards owning their own homes outright. Intrum Mortgages UK Finance Limited is authorised and regulated by the Financial Conduct Authority (FCA number 459016) and is part of the Intrum Group. Intrum Mortgages is also a member of UK Finance.    </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    What is the Mortgage Charter and can it help me?
                </Typography>
                <Typography paragraph>
                    The Mortgage Charter is a government measure that makes it easier for mortgage firms to allow borrowers to reduce their payments for up to six months. This is designed to help those facing payment difficulties get back on track. For more information on the criteria for qualifying, and how this would affect your mortgage repayments
                </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    I’m struggling with the cost of living, what can I do?
                </Typography>
                <Typography paragraph>
                    We understand that talking about money can feel stressful and overwhelming - so if you’re struggling with your mortgage repayments or have concerns about future repayments, it’s important that you call us as soon as possible. We’re ready to support you. In addition, we have collected links to sources of advice and support that may be able to help you with budgeting and money management.
                </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    What is my interest rate and how will it change?
                </Typography>
                <Typography paragraph>
                    Intrum Mortgages operates a variety of interest rates and page two of your monthly statement will confirm which rate applies to your mortgage. Your interest rate will have been agreed when your mortgage was taken out. Depending on the mortgage terms and conditions and your mortgage offer, it will be either be linked to the Bank of England Base Rate (BBR) or a Variable rate.
                    Linked to the Bank of England Base Rate
                    <br /><br />
                    This is the rate set and announced by the Bank of England at meetings of The Monetary Policy Committee (MPC). The interest rate that applies to your mortgage will be made up of two elements, the current BBR rate plus an agreed margin. As a result, your interest rate will change if the BBR changes, however the margin above the BBR will remain the same.
                    <br /><br />
                    For example, if the BBR is 3% and the agreed margin is 3% above the BBR, the interest rate on your mortgage will be 6%. However, if the BBR was then to decrease to 2.5%, your interest rate would also decrease to 5.5% (made up of the new BBR at 2.5%, plus the agreed margin of 3%).
                    <br /><br />
                    A Variable Rate
                    <br /><br />
                    This is a managed rate that is set by Intrum Mortgages, in accordance with your original mortgage offer. It will typically vary with general market conditions which could be either changes to interest rate benchmark SONIA (Sterling Overnight Index Average) and/or the BBR.
                    <br /><br />
                    The applicable rate may also contain a margin element. For example, if the Variable Rate is 3% and the agreed margin is 3% above the Variable Rate, the interest rate on your mortgage will be 6%. However, if there as a decrease to the Variable Rate to 2.5%, your interest rate would also decrease to 5.5% (made up of the new Variable Rate at 2.5%, plus the agreed margin of 3%).

                </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    Do you report to Credit Reference Agencies?
                </Typography>
                <Typography paragraph>
                    Intrum Mortgages reports customer payment information to Experian Limited, TransUnion UK and Equifax Limited.
                    <br /> Please note that simply speaking with our team and engaging with Intrum Mortgages won't impact your credit file.
                </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    How should I protect myself against fraud and cybercrime?
                </Typography>
                <Typography paragraph>
                    To protect yourself from these threats, please continue to be vigilant with phishing emails received or emails with attachments that may contain malware. Always stay alert for any suspicious phone calls, texts or emails from anyone claiming to be from the bank. Trusted organisations will never ask you for your full PIN or password or ask you to move money from your account.
                </Typography>
                <Typography variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}>
                    I have a complaint, what should I do?
                </Typography>
                <Typography paragraph>

                    Intrum Mortgages strives for a high-quality customer experience at all times. If you are unhappy with an aspect of our service, please do contact us so we can help. If we are unable to resolve your complaint, you have the option of referring your concern to the Financial Ombudsman Service. Details of our full complaints procedure are available on the contact us page of this site.
                </Typography>
            </Box>
            <Footer sx={{ backgroundColor: "#0074B8" }} />
        </>
    );
}
