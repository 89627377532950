import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";

export default function MakePayment() {
  return (
    <>
      <Layout />
      <iframe
        id="payment-panel"
        title="payment-portal"
        src="https://deimos.marscapital.co.uk:98/"
        style={{
          width: '100%',
          height: '80vh',
          border: 'none',
        }}
      />
      <Footer />
    </>
  );
}
