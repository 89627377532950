import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../nav/Layout";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CardMedia
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Footer from "../nav/Footer";

export default function Help() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#repayment-help") {
      const element = document.getElementById("repayment-help");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <>
      <Layout />
      <Box sx={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%" }}>
        <Typography
          variant="h6"
          sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
        >
          Useful documents
        </Typography>
        <Typography variant="body1">
          Here are some of the documents we'd advise you to download and keep on file. It's important that we keep you updated with what's going on, things such as the Mortgages Charter for example - and that you have sight<br /> of our Tariff of Charges. Our Financial Statement is a tool we use to understand your own, unique financial circumstances - to be sure we're supporting you in the right way based on your own affordability. Please take a <br /> look and get in touch if you have any questions
        </Typography>

        <Typography variant="h6" sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 600, display: "flex", flexDirection: "row" }}>
          <CardMedia
            component="img"
            image="https://cdn-manager.sbx.svc.intrumuk.com/mars/image/images/download.png"
            alt="Download icon"
            style={{ width: "20px", height: "20px", marginRight: "8px", marginTop: "4px" }}
          />
          View or download
        </Typography>
        <List>
          {[
            {
              href: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/TariffofCharges/IMC123+-+Tariff+of+Charges.pdf",
              text: "Tariff of Charges"
            },
            {
              href: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/Financial+Statement+Form+Dual+Borrowers.docx",
              text: "Financial Statement Form - Dual Borrowers"
            },
            {
              href: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/Financial+Statement+Form+Sole+Borrower.docx",
              text: "Financial Statement - Sole Borrowers"
            },
            {
              href: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/Costofliving/IM+-+Cost+of+Living+Support+Tools.pdf",
              text: "Cost of Living Support Tools"
            },
            {
              href: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/MortgageCharter/IMC113+-+Mortgage+Charter+FAQs.pdf",
              text: "Mortgage Charter FAQs"
            },
            {
              href: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/Complaints/IM+-+Complaints+Procedure.pdf",
              text: "Complaints Procedure"
            },


          ].map((link, index) => (
            <ListItem key={index} sx={{ padding: 0 }}>
              <ListItemIcon>
                <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <a href={link.href} target="_blank" rel="noopener noreferrer">
                    {link.text}
                  </a>
                }
              />
            </ListItem>
          ))}
        </List>

          <Typography
            variant="h5"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Making repayments
          </Typography>
          <Typography paragraph>
            You can make repayments towards your mortgage at any time during the calendar month in which it is due. We recommend you pay by Direct Debit to guarantee that payments reach your mortgage account on time each month.
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Direct Debit
          </Typography>
          <Typography variant="body1">
            Direct Debit is one of the easiest, safest and most reassuring ways of making your monthly mortgage repayment. Your monthly mortgage repayments are made automatically, so they are not overlooked - eliminating the risk of late payment fees and charges+.
            <br /><br />
            The Direct Debit Guarantee protects you and your money. It's offered by all financial institutions that take part in the scheme. A Direct Debit in place means less worry, and appears favourable on your credit file as it shows a consistent commitment to repaying your mortgage.
            <br /><br />
            Your mortgage repayment will be taken via Direct Debit on your chosen date each month. If the payment date falls on a weekend or bank holiday then the Direct Debit payment will be requested on the next working day.
          </Typography>
          {/* Other ways to pay */}
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Other ways to pay
          </Typography>
          <List>
          <ListItem>
            <ListItemText>
              <b>Online</b> -<a href="https://deimos.marscapital.co.uk:98/" target="_blank" rel="noreferrer"> Click here</a>
            </ListItemText>
          </ListItem>
            <ListItem>
              <ListItemText>
                <b>Bank transfer</b>  - Using online banking or via paying-in slip at your bank — please contact us for the correct bank account details
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Standing Order</b> - Call the team for help setting this up
              </ListItemText>
          </ListItem>
          </List>
        <section
          id="repayment-help"
          style={{ scrollMarginTop: "80px" }}
        >
          {/* Repayment Support */}
          <Typography
            variant="h5"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Support with repayments
          </Typography>
          <Typography >
            There’s no question that the rising cost of living and the current economic climate are topics of conversation we’re all having right now, and something we’re hearing about every day in the media. You may be impacted as a result of these things, perhaps worrying about money, or considering compromises to stay on top of your financial commitments.
            <br /><br />
            We understand that talking about money can feel stressful and overwhelming - so if you’re struggling with your mortgage repayments or have concerns about future repayments, we’re ready to support you and encourage you to please get in touch.
            <br /><br />
            We’ve already created a Cost of Living Support Tool document which you may find useful. <a href="https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/Costofliving/IM+-+Cost+of+Living+Support+Tools.pdf"> Click here to take a look.</a> 
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Our team is here to help
          </Typography>
          <Typography >
            Understanding our customers’ needs is at the heart of what we do - so it’s really important to us to always have a clear and up to date snapshot of your individual circumstances.
            <br /><br />
            If you’re worried about money, please call us on <a href="tel:0330 818 6000">0330 818 6000</a>, or email us at: <a href="mailto:info@intrummortgages.co.uk">info@intrummortgages.co.uk</a>
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            What support options are there?
          </Typography>
          <Typography >
            The first thing we’ll do is take you through a <a href="https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/Financial+Statement+Form+Sole+Borrower.docx"> Financial Statement</a> (Income and Expenditure form) step by step, so that we can evaluate with you how we may be able to help you with your current and/or future repayments.
            <br /><br />
            The available options will then be driven by your affordability and circumstances.
            <br /><br />
            We can offer repayment plans to clear arrears and get accounts back on track, as well as discussing the option of temporary change to repayment method, temporary reduction to your monthly payments, and mortgage specific schemes such as <a href="https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/MortgageCharter/IMC113+-+Mortgage+Charter+FAQs.pdf">Mortgage Charter</a>.
            <br /><br />
            It’s important to note that we’re unable to provide financial advice, so please bear that in mind when speaking to the team. And for added peace of mind, talking to us about your circumstances will have no impact to your credit score.
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Things you may find helpful, when assessing your current financial health...
          </Typography>
          <Typography >
            So that we can support you in the right way and check everything that’s coming in and going out each month, a Financial Statement is a great way to note your budget in one place. We’ve talked about completing this over the phone, but if you’d like to complete this in your own time – you can <a href="https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/Financial+Statement+Form+Sole+Borrower.docx"> download the form here</a>, and then send that over to us on email or in the post too.
            <br /><br />
            When completing the statement, we recommend having recent bank statements (to give you a line-by-line list of what you have coming in and going out of your account and utility bills (such as gas, electricity, Council Tax, and water)
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Free, impartial, independent advice
          </Typography>
          <Typography>
            If you feel that you're struggling with your finances, speaking with someone impartial could provide you with options to help you address your current balance, learn more about budgeting, understand your credit file, and more - all of which are things you maybe weren’t already aware of.
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            For independent, free advice – you may want to contact the following organisations:
          </Typography>

          <List>
            <ListItem>
              <ListItemText>
                <a href="https://www.moneyhelper.org.uk" target="_blank" rel="noopener noreferrer">Money Helper</a> is a free service provided by the Money and Pensions Service (government sponsored) where you can receive money and pensions guidance.
                It's a hub of information and provides content which you might find helpful – like
                <a href="https://www.moneyhelper.org.uk/en/money-troubles/way-forward/talking-to-your-creditor" target="_blank" rel="noopener noreferrer"> talking to your creditors</a>, understanding how energy price increases may impact you or how to deal with debt.
                Money Helper also offer a
                <a href="https://www.moneyhelper.org.uk/en/homes/buying-a-home/mortgage-calculator" target="_blank" rel="noopener noreferrer"> Mortgage Calculator</a> so you can check potential mortgage costs should you decide to re-mortgage.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                You can also find information about government benefits and schemes offering help for customers impacted by the cost-of-living crisis, how they work and whether you may be eligible.
                For example, you can find information about the
                <a href="https://www.gov.uk/the-warm-home-discount-scheme" target="_blank" rel="noopener noreferrer"> Warm Home Discount</a>,
                <a href="https://www.gov.uk/cold-weather-payment" target="_blank" rel="noopener noreferrer"> Cold Weather Payments</a>, and other support packages.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <a href="https://www.stepchange.org/" target="_blank" rel="noopener noreferrer">StepChange</a> is a charity offering debt advice and debt solutions – such as debt management plans and insolvency solutions.
                By contacting them and explaining your situation, they will help you explore your options and choose a solution which will best suit your circumstances.
                They also provide information about debt, how to manage and understand it.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <a href="https://www.nationaldebtline.org/" target="_blank" rel="noopener noreferrer">National Debt Line</a> is a charity offering free and independent advice over the phone and online.
                They offer a
                <a href="https://www.nationaldebtline.org/fact-sheet-library/" target="_blank" rel="noopener noreferrer"> Digital Advice Tool</a> which gives you access to instant and tailored advice.
                They also have a
                <a href="https://www.nationaldebtline.org/fact-sheet-library/" target="_blank" rel="noopener noreferrer"> Cost of living library</a> on their website where you can find useful information about money management and help available to you if you are struggling with payments.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <a href="https://www.mentalhealthandmoneyadvice.org/en/" target="_blank" rel="noopener noreferrer">Mental Health and Money Advice</a> offers support and advice on the cost of living as well as the impact on mental health.
                Money can be a big influence on our mental health, and it’s important we know who to talk to.
              </ListItemText>
            </ListItem>
          </List>

          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Help, Information and Advice if you are in arrears or face repossession
          </Typography>

          <List>
            <ListItem>
              <ListItemText>
                <a href="https://www.gov.uk/support-for-mortgage-interest" target="_blank" rel="noopener noreferrer"> GOV.UK – Unemployed or facing redundancy? / Repossession</a>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <a href="https://www.citizensadvice.org.uk/debt-and-money/help-with-debt/" target="_blank" rel="noopener noreferrer"> Citizens Advice Bureau (CAB) – CAB Advice Guide</a>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <a href="https://england.shelter.org.uk/housing_advice/repossession" target="_blank" rel="noopener noreferrer">Shelter England</a>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <a href="https://scotland.shelter.org.uk/" target="_blank" rel="noopener noreferrer">Shelter Scotland</a>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <a href="https://sheltercymru.org.uk/" target="_blank" rel="noopener noreferrer">Shelter Wales</a>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <a href="https://www.housingadviceni.org/" target="_blank" rel="noopener noreferrer">Housing Advice NI</a>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <a href="https://www.nhas.org.uk/" target="_blank" rel="noopener noreferrer">National Homelessness Advice Service (NHAS) – Are you worried about your mortgage?</a>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <a href="https://www.ukfinance.org.uk/" target="_blank" rel="noopener noreferrer">UK Finance</a>
              </ListItemText>
            </ListItem>
          </List>

          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Independent Financial Advice
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                <a href="https://www.unbiased.co.uk" target="_blank" rel="noopener noreferrer">Unbiased.co.uk</a> – Find an independent financial / mortgage adviser
              </ListItemText>
            </ListItem>
          </List>

          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
          >
            Mortgage Industry Bodies
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                <a href="https://www.fca.org.uk" target="_blank" rel="noopener noreferrer">Financial Conduct Authority (FCA)</a> – Financial Services regulator
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <a href="https://www.cml.org.uk/cml/home" target="_blank" rel="noopener noreferrer">Council of Mortgage Lenders (CML)</a> – Trade body for the residential mortgage industry
              </ListItemText>
            </ListItem>
          </List>
        </section>
      </Box>
      <Footer />
    </>
  );
}