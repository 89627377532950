import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
  Box,
  Stack,
  useTheme,
  useMediaQuery,
  CardMedia,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../images/intrum_mars_small_logo.png"
import { Outlet, Link, NavLink } from "react-router-dom";
import { useNav } from "../../context/NavProvider";

export default function Navbar() {
  const { navigation } = useNav();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  },);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ py: 2 }}>
        <Link to="/">
          <CardMedia
            sx={{
              width: 160,
              maxHeight: { xs: 80, md: 180 },
              maxWidth: { xs: 130, md: 150 },
              py: 3,
            }}
            image={Logo}
            alt="Logo"
          />
        </Link>
      </Box>
      <List sx={{
        '& .MuiListItemText-root': {
          'span': {
            fontFamily: 'IntrumFont, sans-serif',
            fontWeight: 600,
          },
          ': hover': {
            textDecoration: "underline",
            '&, & .MuiListItemIcon-root': {
              color: "#0074B8",
            },
          },
        },
      }}>
        {navigation &&
          navigation.map((item) => (
            <ListItem key={item.id}>
              <NavLink
                id={item.id}
                to={item.href}
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive
                    ? "#1e4978"
                    : "#000",
                })}
                sx={{
                  "&.active": {
                    color: "#1e4978",
                  },
                }}
              >
                <ListItemText primary={item.name} />
              </NavLink>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          top: isVisible ? 0 : "-64px",
          transition: "top 0.3s",
          backgroundColor: "#fff",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Link to="/" style={{ marginRight: theme.spacing(4) }}>
              <CardMedia
                sx={{
                  mt: 3,
                  mb: 3,
                  ml: 7,
                  mr: 7,
                  width: 160,
                  maxHeight: { xs: 80, md: 180 },
                  maxWidth: { xs: 130, md: 150 },
                  py: 3,
                }}
                image={Logo}
                alt="Logo"
              />
            </Link>

            {!isMobile && (
              <Stack direction="row" spacing={2}>
                {navigation &&
                  navigation.map((item) => (
                    <MenuItem
                      key={item.id}
                      sx={{
                        mt: 3,
                        ml: 4,
                        mb: 3,
                        py: 3,
                        "& .nav-link": {
                          width: "100%",
                          textDecoration: "none",
                          fontWeight: 600,
                          color: "#000",
                        },
                        "& .nav-link:hover": {
                          color: "#0074B8",
                          textDecoration: "underline",
                        },
                        "& .nav-link.active": {
                          color: "#1e4978",
                        },
                      }}
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <Typography textAlign="center">
                        <NavLink
                          id={item.id}
                          to={item.href}
                          className={({ isActive }) =>
                            isActive ? "nav-link active" : "nav-link"
                          }
                        >
                          {item.name}
                        </NavLink>
                      </Typography>
                    </MenuItem>
                  ))}
              </Stack>
            )}
          </Box>
          {isMobile && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{
                  mt: 3,
                  mb: 3,
                  ml: 3,
                  mr: 3,
                }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                  "& .MuiDrawer-paper": {
                    width: "80vw",
                  },
                }}
              >
                {drawer}
              </Drawer>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Outlet />
    </Box>
  );
}
