import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";
import { Box, Typography } from "@mui/material";

export default function RebrandArticle() {

    return (
        <>
            <Layout />
            <Box sx={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%" }}>
                <Typography
                    variant="h5"
                    sx={{
                        color: "#000",
                        marginBottom: "1rem",
                        fontWeight: 800,
                    }}
                >
                    Why has Mars Capital rebranded as Intrum Mortgages?
                </Typography>
                <Typography paragraph>
                    In June 2023, Mars Capital became part of Intrum Group. The acquisition has given us a fantastic opportunity to enhance how we operate, and now that the move is finalised, we’re excited to be rebranding in line with the wider business.
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Mars acquired by Intrum UK
                </Typography>
                <Typography paragraph>
                    The rebranding happened because Mars Capital was acquired by credit management business Intrum UK in 2023. The acquisition is a fantastic opportunity to enhance the way we operate and the services we will be able to offer our customers in future. Intrum is a tech-driven company which, like Mars Capital, puts the customer at the heart of everything it does. The awards Intrum have won speak for themselves – click here. It’s taken a little while to unite the companies but, now that the businesses have joined forces, we’re excited to become Intrum Mortgages.   </Typography>
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Same loans, same great service
                </Typography>
                <Typography paragraph>
                    It’s important to emphasise that none of the loans we manage have been sold. They’re still supported by the same fantastic team and there have been no changes to them – so the only thing that has changed is our business name. Customers will see our new logo on their letters but will receive their statements in the same way as before.
                    <br /><br />
                    “At Intrum Mortgages we’ll be working as hard as ever to help our customers make their repayments and work towards owning their own homes outright,” says UK Managing Director Jim Appleby. “The acquisition is an exciting stage in our development as it means we will be able to offer an even more efficient and innovative service in future.”
                </Typography >
            </Box>
            <Footer sx={{ backgroundColor: "#0074B8" }} />
        </>
    );
}
