import React from "react";
import { useLocation } from "react-router-dom";
import background from "../../images/blue-banner-background.png";
import finance from "../../images/house-money-stock.jfif";
import house from "../../images/house-stock.jfif";
import street from "../../images/house-street-stock.jfif";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CtaButton from "../Ctabuttons";

export default function Banner() {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultBackgroundImage = `url(${background})`;
  const financeBackground = `url(${finance})`;
  const defaultBackgroundPosition = "center center";
  const houseBackground = `url(${house})`;
  const houseStreetBackground = `url(${street})`;
  const isArticleRoute = ["/RebrandArticle", "/FinanceControlArticle", "/PayingTooMuchArticle"].includes(location.pathname);

  const getContentByRoute = () => {
    switch (location.pathname) {
      case "/About":
        return {
          title: "About us",
          subtitle: "Who are Intrum Mortgages",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
        };
      case "/Contact":
        return {
          title: "Contact us",
          subtitle: "Need to speak to us about something?",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
        };
      case "/MakePayment":
        return {
          title: "Make a Payment",
          subtitle: "How do I make a payment?",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
        };
      case "/Help":
        return {
          title: "Help & Resources",
          subtitle: "Need some help?",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
        };
      case "/Faqs":
        return {
          title: "FAQs",
          subtitle: "Got a question?",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
        };
      case "/RebrandArticle":
        return {
          title: "Why has Mars Rebranded",
          subtitle: "Article",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
          backgroundImage: houseBackground,
          backgroundPosition: "center 48%",
        };
      case "/FinanceControlArticle":
        return {
          title: "Take control of your finances",
          subtitle: "Article",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
          backgroundImage: houseStreetBackground,
          backgroundPosition: "center center",
        };
      case "/PayingTooMuchArticle":
        return {
          title: "Am I paying too much for my mortgage",
          subtitle: "Article",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
          backgroundImage: financeBackground,
          backgroundPosition: "center bottom 42%",
        };
      case "/making-repayments/sorry":
        return {
          title: "Make a payment",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
        };
      case "/making-repayments/thank-you":
        return {
          title: "Make a payment",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: false,
          showCallUsButton: false,
        };
      default:
        return {
          title: "Leading the way to becoming Mortgage free",
          subtitle: "We're here to support you",
          titleFontSize: isMobile ? "28px" : "40px",
          subtitleFontSize: isMobile ? "16px" : "15px",
          showMakePaymentButton: true,
          showCallUsButton: true,
        };
    }
  };

  const {
    title,
    subtitle,
    titleFontSize,
    subtitleFontSize,
    backgroundImage = defaultBackgroundImage,
    backgroundPosition = defaultBackgroundPosition,
    showMakePaymentButton,
    showCallUsButton,
  } = getContentByRoute();

  const subtitleColor = "#BFE2F4";
  const typographyColor = "#fff";

  return (
    <Box
      sx={{
        backgroundImage: backgroundImage,
        backgroundSize: "cover",
        backgroundPosition: backgroundPosition,
        backgroundRepeat: "no-repeat",
        height: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        px: isMobile ? 4 : 12,
        py: isMobile ? 2 : 1,
      }}
    >
      {/* Conditionally render the semi-transparent dark overlay only for article routes */}
      {isArticleRoute && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 1,
          }}
        ></Box>
      )}

      {/* Text and buttons */}
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography
          sx={{
            color: subtitleColor,
            fontSize: subtitleFontSize,
            paddingTop: isMobile ? "5px" : "10px",
            paddingBottom: isMobile ? "8px" : "20px",
            fontWeight: 400,
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          sx={{
            color: typographyColor,
            fontSize: titleFontSize,
            fontWeight: 800,
            paddingBottom: isMobile ? "8px" : "30px",
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "flex-start",
          }}
        >
          {showMakePaymentButton && (
            <a href="https://deimos.marscapital.co.uk:98/" target="_blank" rel="noopener noreferrer">
            <CtaButton
              buttonTitle="Make a payment"
                buttonColor="#fff"
              buttonTextColor="#000"
                buttonWeight={600}
              outlined={false}
              />
            </a>
          )}
          {showCallUsButton && (
            <CtaButton
              buttonTitle="Call Us"
              target="/Contact"
              buttonTextColor="#fff"
              outlined={true}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
