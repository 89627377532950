import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";
import { Box, Typography } from "@mui/material";
export default function FinanceControlArticle() {

    return (
        <>
            <Layout />
            <Box sx={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%" }}>
                <Typography
                    variant="h5"
                    sx={{
                        color: "#000",
                        marginBottom: "1rem",
                        fontWeight: 800,
                    }}
                >
                    Take control of your finances
                </Typography>
                <Typography paragraph>
                    With the cost of living crisis and higher interest rates, most people have found that their money isn’t stretching as far as it used to. Even when times are good, it makes sense to spring clean your finances from time to time.
                    Here are some tips for making sure you’re on track financially…
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Check your mortgage
                </Typography>
                <Typography paragraph>
                    Your mortgage payments are likely to be your biggest monthly expense so making sure you’re on the best available rate is important. If you’ve reached the end of your fixed term and are up to date on repayments, you may be eligible to move to a cheaper deal elsewhere. This could save you money in the long term.
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Consider overpaying
                </Typography>
                <Typography paragraph>
                    If you do have some money to spare, consider overpaying on your mortgage.
                    Because you’re paying interest on a large amount, doing this will reduce the length of your mortgage and save you a lot of money.
                    We suggest you only do this if you have adequate savings to cope with the unforeseen day to day expenses that crop up for us all – such as the washing machine breaking, car problems or vet bills.
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Set up a budget
                </Typography>
                <Typography paragraph>
                    Having a budget is the best way to take control of your finances. This will give you an overview of your monthly fixed and variable expenses, so you can see if you have any extra cash left over. It may take some time to create but is the foundation to making good financial choices. A good budget will enable you to see if there are places you can cut back.
                    For example, many of us spend more money than necessary on food. With a little planning, you can save a lot.
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Go through all subscriptions
                </Typography>
                <Typography paragraph>
                    Many of us have subscriptions we don't really use. This applies to everything from magazines to gyms and television streaming subscriptions and can add up to large sums per month. Take an evening to go through your subscriptions and cancel the ones you don't use.
                    Any cash released can always be set aside for savings.
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Start saving for your goals
                </Typography>
                <Typography paragraph>
                    Even if you haven’t been good at saving in the past, it’s not too late to start. There are several tricks that make saving fun. Perhaps you’d like to put money aside for a holiday or build up a buffer to give you peace of mind? Set a goal and find the methods that work best for you. For example, you can try the savings app Dreams to keep track. Small amounts add up over time, giving you peace of mind and the money to achieve your goals.      </Typography >

                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Pay off credit cards
                </Typography>
                <Typography paragraph>
                    Interest rates on credit cards are often high, and unpaid bills can quickly become expensive.
                    If you have the means to do so, you should pay off your credit card bill immediately.
                    If you can’t pay everything at once, it's smart to make a plan for how you’re going to pay off the bill as quickly as possible.
                    For example, you can enter it as a fixed item in your budget, so that you don’t spend the money on something else.
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Get rid of problem debt
                </Typography>
                <Typography paragraph>
                    If you have received a debt collection letter or are worried you won’t be able to pay a bill, it is important that you deal with it straight away. The sooner you deal with your problem debt, the sooner you will get it paid off.
                    Speak to your creditors for advice or talk to a debt advice charity.
                </Typography >
            </Box>
            <Footer sx={{ backgroundColor: "secondary.main" }} />
        </>
    );
}
