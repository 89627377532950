import React, { useState } from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const faqItems = [
  {
    question: "Who is Intrum Mortgages?",
    answer: `
      <p>Intrum Mortgages buy and manage hundreds of millions of pounds worth of mortgages. We are the legal owners of these accounts and pride ourselves on helping our customers work towards owning their own homes outright.</p>
      <p>Intrum Mortgages UK Finance Limited is authorised and regulated by the Financial Conduct Authority (FCA number 459016) and is part of the Intrum Group. Intrum Mortgages is also a member of UK Finance.</p>
    `,
  },
  {
    question: "Why have you changed your name to Intrum Mortgages?",
    answer: `
      <p>In June 2023, Mars Capital became part of Intrum Group. The acquisition has given us a fantastic opportunity to enhance how we operate, and now that the move is finalised, we’re excited to be rebranding in line with the wider business.</p>
    `,
  },
  {
    question: "Has my Mortgage been sold to another company?",
    answer:
      "No, it hasn’t. It’s exactly the same loan supported by the same fantastic team of professionals, and there are no changes to it other than the name.",
  },
  {
    question: "Will I still receive monthly and/or annual statements?",
    answer: `
      <p>Yes. You’ll receive these if you have been already. They will have our new logo and may look slightly different, but all of the details you’re used to receiving will be included.</p>
    `,
  },
  {
    question: "What is the Mortgage Charter and can it help me?",
    answer: `
      <p>The Mortgage Charter is a government measure that makes it easier for mortgage firms to allow borrowers to reduce their payments for up to six months. This is designed to help those facing payment difficulties get back on track.</p>
      <p>For more information on the criteria for qualifying, and how this would affect your mortgage repayments, read our dedicated Mortgage Charter document.</p>
    `,
  },
  {
    question: "I'm struggling with the cost of living, what can I do?",
    answer: `
      <p>We understand that talking about money can feel stressful and overwhelming - so if you’re struggling with your mortgage repayments or have concerns about future repayments, it’s important that you call us as soon as possible. We’re ready to support you.</p>
      <p>In addition, we have collected links to sources of advice and support that may be able to help you with budgeting and money management.</p>
    `,
  },
  {
    question: "What is my interest rate and how will it change?",
    answer: `
      <p>Intrum Mortgages operates a variety of interest rates and page two of your monthly statement will confirm which rate applies to your mortgage. Your interest rate will have been agreed when your mortgage was taken out. Depending on the mortgage terms and conditions and your mortgage offer, it will either be linked to the Bank of England Base Rate (BBR) or a Variable rate.</p>

      <p><strong>Linked to the Bank of England Base Rate:</strong> This is the rate set and announced by the Bank of England at meetings of The Monetary Policy Committee (MPC). The interest rate that applies to your mortgage will be made up of two elements, the current BBR rate plus an agreed margin. As a result, your interest rate will change if the BBR changes, however, the margin above the BBR will remain the same.</p>

      <p>For example, if the BBR is 3% and the agreed margin is 3% above the BBR, the interest rate on your mortgage will be 6%. However, if the BBR was then to decrease to 2.5%, your interest rate would also decrease to 5.5% (made up of the new BBR at 2.5%, plus the agreed margin of 3%).</p>

      <p><strong>A Variable Rate:</strong> This is a managed rate that is set by Intrum Mortgages, in accordance with your original mortgage offer. It will typically vary with general market conditions, which could include changes to the interest rate benchmark SONIA (Sterling Overnight Index Average) and/or the BBR.</p>

      <p>The applicable rate may also contain a margin element. For example, if the Variable Rate is 3% and the agreed margin is 3% above the Variable Rate, the interest rate on your mortgage will be 6%. However, if there is a decrease to the Variable Rate to 2.5%, your interest rate would also decrease to 5.5% (made up of the new Variable Rate at 2.5%, plus the agreed margin of 3%).</p>
    `,
  },
  {
    question: "What should I do if I have any questions about this change?",
    answer: `
      <p>If you’d like to ask something else, or for questions about your account in general – we’re here to help. Feel free to contact us.</p>
    `,
  },
];

export default function Accordion() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {faqItems.map((item, index) => (
        <MuiAccordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{ boxShadow: "none", ":before": { backgroundColor: "transparent" } }}
        >
          <AccordionSummary
            expandIcon={expanded === `panel${index}` ? <RemoveIcon sx={{ color: "#000" }} /> : <AddIcon sx={{ color: "#000" }} />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{ margin: "10px", boxShadow: "none", ":hover": { color: "#0074B8", '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': { color: "#0074B8" } } }}
          >
            <Typography sx={{ fontWeight: 600 }}>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography dangerouslySetInnerHTML={{ __html: item.answer }} />
          </AccordionDetails>
        </MuiAccordion>
      ))}
      <Typography sx={{ mt: 2, fontWeight: 600, color: "#0074B8" }}>
        <a href="/Faqs" target="_blank" rel="noopener">See all FAQs</a>
      </Typography>
    </div>
  );
}
