import { useState, useEffect, createContext, useContext } from "react";
const NavContext = createContext();
export const useNav = () => useContext(NavContext);

const NavProvider = ({ children }) => {
  const [navigation, setNavigation] = useState(null);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    setNavigation([
      {
        name: "Help & Resources",
        id: "help-resources",
        href: "/Help",
        current: false,
        hide: false,
      },
      {
        name: "Make a Payment",
        id: "make-payment",
        href: "https://deimos.marscapital.co.uk:98/",
        current: false,
        hide: false,
      },
      {
        name: "About us",
        id: "about-us",
        href: "/About",
        current: false,
        hide: false,
      },
      {
        name: "Contact us",
        id: "contact",
        href: "/Contact",
        current: false,
        hide: false,
      },
    ]);
  }, []);

  const contextValue = {
    navigation,
    showFooter,
    setShowFooter,
  };

  return (
    <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
  );
};

export default NavProvider;
