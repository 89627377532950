import React from "react";
import { Box } from "@mui/material";
import Navbar from "./Navbar";
import Banner from "./Banner";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ mt: "80px" }}>
        <Banner />
      </Box>
      <Outlet />
    </Box>
  );
}
