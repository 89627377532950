import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ScrollToTop from "./ScrollToTopButton";

const layoutStyles = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const mainContentStyles = {
  flexGrow: 1,
};

const Footer = () => {
  const footerData = [
    {
      title: "Contact",
      links: [{ label: "Contact", url: "/Contact" }],
    },
    {
      title: "FAQs",
      links: [
        { label: "FAQs", url: "/Faqs" },
        {
          label: "Mortgage Charter FAQs",
          url: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/MortgageCharter/IMC113+-+Mortgage+Charter+FAQs.pdf",
        },
      ],
    },
    {
      title: "Help & Resources",
      links: [{ label: "Help", url: "/Help" }],
    },
    {
      title: "Privacy notice",
      links: [
        {
          label: "Privacy Notice",
          url: "https://cdn-manager.sbx.svc.intrumuk.com/intrum/documents/PrivacyNotice.pdf",
        },
      ],
    },
  ];

  return (
    <Box component="div" sx={layoutStyles}>
      <Box component="main" sx={mainContentStyles}></Box>
      <ScrollToTop />
      <Box
        component="footer"
        sx={{
          backgroundColor: "#1D5271",
          color: "#FFF",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          paddingY={{ xs: 5, md: 10 }}
          paddingX={{ xs: 2, md: 10 }}
        >
          {/* First Column */}
          <Grid item xs={12} md={6}>
            {footerData.slice(0, 2).map((item, idx) => (
              <Box key={idx} >
                <List dense sx={{
                  padding: 0,
                }}>
                  {item.links.map((link, idx) => (
                    <ListItem key={idx}>
                      <ListItemButton
                        component="a"
                        href={link.url}
                        target="_blank"
                        sx={{
                          color: "#FFF",
                          padding: 0,
                        }}
                      >
                        <ListItemText primary={link.label} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={6}>
            {footerData.slice(2).map((item, idx) => (
              <Box key={idx}>
                <List dense sx={{
                  padding: 0,
                }}>
                  {item.links.map((link, idx) => (
                    <ListItem key={idx}>
                      <ListItemButton
                        component="a"
                        href={link.url}
                        target="_blank"
                        sx={{
                          padding: 0,
                          color: "#FFF",
                        }}
                      >
                        <ListItemText primary={link.label} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
