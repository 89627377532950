import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function About() {

    return (
        <>
            <Layout />
            <Box sx={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%" }}>
                <Typography
                    variant="h5"
                    sx={{
                        marginBottom: "1rem",
                        fontWeight: 800,
                    }}
                >
                    About Intrum Mortgages
                </Typography>
                <Typography paragraph>
                    At Intrum Mortgages, we provide a comprehensive service to our
                    customers through our experienced team of mortgage professionals, and
                    we are fully committed to treating customers fairly. <br /><br />Unlike most other
                    mortgage companies, Intrum Mortgages doesn’t market mortgage products
                    via a network of branches, mortgage brokers, or the internet. Instead,
                    we purchase mortgage portfolios from other lenders and take over their
                    administration. The mortgages we administer are our own as we have
                    legal ownership of the loan agreements. We manage every aspect of
                    mortgage administration, using our own mortgage experts and technology
                    to provide our customers with a high quality and reliable service.
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
                >
                    We help customers to:
                </Typography>
                <List>
                    {[
                        "Make monthly mortgage repayments",
                        "Resolve repayment difficulties",
                        "Provide annual mortgage and redemption statements",
                        "Manage selling issues",
                        "Manage letting issues",
                        "Understand deeds of postponement",
                        "Coordinate changes to a mortgage contract",
                    ].map((text, index) => (
                        <ListItem key={index} sx={{ padding: 0 }}>
                            <ListItemIcon>
                                <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
                <Typography
                    variant="h6"
                    sx={{ marginTop: "1rem", marginBottom: "0.5rem", fontWeight: 800 }}
                >
                    Who we are
                </Typography>
                <Typography paragraph sx={{ marginTop: "1rem" }}>
                    Formed in 2008, Intrum Mortgages is a British mortgage company.
                    Previously known as Mars Capital, the business became part of credit
                    management group Intrum in 2023. Intrum has a presence in 20 European
                    markets. Headquartered in Stockholm, Sweden, it is publicly listed on
                    the Nasdaq Stockholm exchange.
                </Typography>
                <Typography paragraph>
                    Intrum Mortgages, formerly known as Mars Capital, is a trading name of
                    Intrum Mortgages UK Finance Limited. Registered in England & Wales No.
                    5859881. Its registered office is at Belvedere, 12 Booth Street,
                    Manchester, M2 4AW.
                </Typography>
                <Typography paragraph>
                    Intrum Mortgages UK Finance Limited is authorised and regulated by the
                    Financial Conduct Authority (FCA number 459016). Intrum Mortgages UK
                    Finance Limited is part of the Intrum Group, whose registered UK
                    office is at The Omnibus Building, Lesbourne Road, Reigate, Surrey RH2
                    7JP.
                </Typography>
            </Box>
            <Footer />
        </>
    );
}
