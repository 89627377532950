import React from 'react';
import ReactDOM from 'react-dom/client';
import NavProvider from "./context/NavProvider";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "./index.css";
import App from "./App";

const theme = createTheme({
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <NavProvider>
      <App />
    </NavProvider>
  </ThemeProvider>
);