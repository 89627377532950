import React from "react";
import Layout from "../nav/Layout";
import Footer from "../nav/Footer";
import { Box, Typography } from "@mui/material";

export default function PayingTooMuchArticle() {

    return (
        <>
            <Layout />
            <Box sx={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "2%" }}>
                <Typography
                    variant="h5"
                    sx={{
                        color: "#000",
                        marginBottom: "1rem",
                        fontWeight: 800,
                    }}
                >
                    Am I paying too much for my mortgage?
                </Typography>
                <Typography paragraph>
                    With the cost of living crisis and rising interest rates, many of us have been feeling that our money doesn’t stretch as far as it used to. As your mortgage payment is likely to be your biggest monthly expense, making sure you’re on the best possible deal can make a big difference.

                    Intrum Mortgages operates a variety of interest rates and you can check what your rate is on your monthly statement. These are linked to different factors, such as the Bank of England base rate, which means they rise and you pay more when interest rates are high.

                    Changes to the way mortgage lenders calculate whether a mortgage is affordable have made it simpler for you to obtain a cheaper mortgage with a new lender as long as you meet the eligibility criteria. It may be worth looking to see if you can secure a better deal.
                </Typography >
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Are you eligible to move?
                </Typography>
                <Typography paragraph>
                    An eligible consumer is one who already has a mortgage contract and would like to move to a new mortgage for the same value as the current, outstanding balance; which must be secured on the same property. As long as you don’t currently have a payment shortfall, and haven’t had one at any point in the last twelve months, you can take advantage of this.

                    Intrum Mortgages does not carry out mortgage lending services, as we buy our loans from other lenders, so we can’t offer you a different rate. However, other mortgage lenders may be able to offer you a new mortgage on a lower rate, so it’s worth shopping around.
                </Typography>
                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Finding out more about remortgaging
                </Typography>
                <Typography paragraph>
                    The Money Advice Service is a free service provided by government department the Money and Pensions Service. There is a wealth of helpful information on switching to a new mortgage on their MoneyHelper website https://www.moneyhelper.org.uk/en/homes/buying-a-home/

                    MoneyHelper includes a Mortgage Calculator that allows you to check potential mortgage costs should you decide to re-mortgage. You can also check how changing interest rates will impact your current mortgage repayments and the site has useful advice on how to weigh up the full costs of moving your mortgage to a new provider.
                </Typography >

                <Typography variant="h6" sx={{
                    color: "#000",
                    marginBottom: "1rem",
                    fontWeight: 800,
                }}>
                    Not eligible?
                </Typography>
                <Typography paragraph>
                    If you have missed a payment in the last year but are back on track, you’ll be able to consider switching to a cheaper deal when you have a 12-month record of paying, so it’s important to ensure your repayment reach us on time each month. Put a reminder in your calendar to shop around for a better rate when that time is up.

                    If you’ve missed a payment and are worried about the impact this is having or are struggling to make your payments now, pick up the phone and talk to us sooner rather than later. We can help go through your options and there are lots of things we can do to support you. There are lots of ways to contact us.

                    We’ve also collected a list of sources of information on budgeting and financial advice that may be useful.     </Typography >
            </Box>
            <Footer sx={{ backgroundColor: "secondary.main" }} />
        </>
    );
}
